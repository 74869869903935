import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import { Typography } from "@mui/material";

import { styled } from "@mui/system";
import { fonts } from "~/theme";

import artifact1 from "./artifact1.png";
import artifact2 from "./artifact2.png";
import artifact3 from "./artifact3.png";
import artifact4 from "./artifact4.png";
import artifact5 from "./artifact5.png";
import artifact6 from "./artifact6.png";

interface Properties extends HTMLAttributes<HTMLElement> {}

const Container = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: "1440px",
    margin: "116px auto 0 auto",
    borderTop: "1px solid rgba(0, 0, 0, 0.08)",
    height: "1048px",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
        marginTop: "96px",
        padding: "0 16px",
        flexDirection: "column",
        height: "auto",
    },
    "& > div": {
        width: "20%",
        borderRight: "1px solid rgba(0, 0, 0, 0.08)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            borderRight: 0,
        },
        "&:last-child": {
            borderRight: 0,
        },
    },
}));

const Section1 = styled("div")(({ theme }) => ({
    padding: "184px 48px 0 48px",
    backgroundImage: `url(${artifact1})`,
    backgroundPosition: "bottom left",
    [theme.breakpoints.down("sm")]: {
        padding: "120px 0 56px 0",
        backgroundImage: `url(${artifact5})`,
        backgroundPosition: "top right",
        backgroundSize: "50% !important",
    },
}));

const Author = styled(Typography)(({ theme }) => ({
    ...fonts.secondBannerBigTextSM,
    fontSize: "32px",
    lineHeight: "39px",
    marginBottom: "8px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginBottom: "4px",
    },
}));

const Collection = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallText,
    fontSize: "32px",
    lineHeight: "39px",
    fontWeight: 400,
    marginBottom: "178px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginBottom: "150px",
    },
}));

const Date = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: "55px",
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginBottom: "8px",
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallText,
    marginBottom: "16px",
    fontSize: "32px",
    lineHeight: "39px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginBottom: "8px",
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
    },
}));

const Section2 = styled("div")(({ theme }) => ({
    padding: "448px 48px 0 48px",
    backgroundImage: `url(${artifact2})`,
    backgroundPosition: "right 184px",
    backgroundSize: "237px !important",
    [theme.breakpoints.down("sm")]: {
        padding: "0 0 56px 0",
        background: "none",
    },
}));

const Section3 = styled("div")(({ theme }) => ({
    padding: "448px 48px 0 48px",
    [theme.breakpoints.down("sm")]: {
        padding: "0 0 56px 0",
    },
}));

const Section4 = styled("div")(({ theme }) => ({
    padding: "448px 48px 0 48px",
    backgroundImage: `url(${artifact3})`,
    backgroundPosition: "bottom left",
    [theme.breakpoints.down("sm")]: {
        padding: "0 0 56px 0",
        background: "none",
    },
}));

const Section5 = styled("div")(({ theme }) => ({
    padding: "448px 48px 0 48px",
    backgroundImage: `url(${artifact4})`,
    backgroundPosition: "top right",
    [theme.breakpoints.down("sm")]: {
        padding: "0 0 306px 0",
        backgroundImage: `url(${artifact6})`,
        backgroundPosition: "bottom left",
        backgroundSize: "auto !important",
    },
}));

export const Roadmap: FunctionComponent<Properties> = ({
    children,
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <Container className={className} {...rest}>
            <Section1>
                <Author>Paul Kneale</Author>
                <Collection>Free_Software</Collection>

                <Date>May 12</Date>
                <Title>NFT Drop 1</Title>
                <Description>
                    The first collection of 12 unique digital works available
                    for buy now on LiveArtX.
                </Description>
            </Section1>
            <Section2>
                <Date>May 19</Date>
                <Title>NFT Drop 2</Title>
                <Description>
                    The second collection of 12 unique digital works available
                    for buy now on LiveArtX.
                </Description>
            </Section2>
            <Section3>
                <Date>May 20</Date>
                <Title>Physical Art</Title>
                <Description>
                    Collectors who purchase 3 NFTs will be sent a physical
                    signed edition.
                </Description>
            </Section3>
            <Section4>
                <Date>May 26</Date>
                <Title>Meet Paul Kneale</Title>
                <Description>
                    The top buyer gets the chance to collaborate with Paul
                    Kneale to create an official Free Software piece.{" "}
                </Description>
            </Section4>
            <Section5>
                <Date>September</Date>
                <Title>Future Drops</Title>
                <Description>
                    All winners will be added to the allowlist for Paul Kneale’s
                    future drops.
                </Description>
            </Section5>
        </Container>
    );
};
